<template>
  <div class="login" ref="appRef">
    <div class="login_bg">
      <!-- <div class="login_adv">
          <div class="login_adv__title">
            <h2>智慧供热平台</h2>
          </div>
        </div> -->
      <div class="login_title">智慧供热平台登录</div>
      <div class="login_main">
        <div class="login-form">
          <div class="login-header">
            欢迎登录
          </div>
          <el-form class="ruleForm" :model="ruleForm" :rules="rules" ref="ruleForm" id="ruleForm" label-width="0" size="large">
            <el-form-item prop="user">
              <el-input class="input" v-model="ruleForm.user" prefix-icon="el-icon-user" clearable placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item prop="password" style="margin-top: 30px;">
              <el-input class="input" v-model="ruleForm.password" prefix-icon="el-icon-lock" clearable show-password
                placeholder="请输入密码"></el-input>
            </el-form-item>
            <!-- <el-form-item> -->
              <el-button class="loginbtn" @click="submitForm">登录</el-button>
            <!-- </el-form-item> -->
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      ruleForm: {
        user: "",
        password: "",
        code: "",
        autologin: false
      },
      rules: {
        user: [
          { required: true, message: "请输入账号", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" }
        ]
      }
    }
  },
  beforeCreate: function () {
    document.querySelector('body').setAttribute('style', 'background-attachment: fixed;overflow: hidden;')
  },
  methods: {
    submitForm(formName) {
      if (this.ruleForm.user != "admin" || this.ruleForm.password != "111") {
        this.$message({
          message: '账号或密码错误',
          type: 'error'
        });
        return
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$auth.setToken("1wVFX6Bmsx")
          this.$router.push({ path: "/index" });
        } else {
          return false;
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin text{
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #E5F6FF;
}
::v-deep .el-form-item .el-form-item__content .el-input__prefix{
  font-size: 16px;
  color: #00A7FF; 
}
::v-deep .el-form-item .el-form-item__content .el-input__inner{
  background: #093F6B;
  border: 1px solid #0062C2;
  @include text();
}
::v-deep .el-form-item .el-form-item__content .el-input__inner::placeholder{
  @include text()
}
.login {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  .login_title{
    // position: absolute;
    margin: 0 auto;
    background: url('../assets/images/login/标题.png');
    width: 90%;
    // width: 1744px;
    height: 116px;
    line-height: 90px;
    font-size: 43px;
    font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
    font-weight: bold;
    color: #E5F6FF;
    text-align: center;
  }
  
  .ruleForm{
    width: 420px;
    margin: 50px auto;
    text-align: center;
   
    .loginbtn{
      margin: 70px auto 0;
      background: url('../assets/images/login/loginbtn.png') no-repeat;
      width: 148.53px;
      height: 54.61px;
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #E5F6FF;
      border: none;
    }
  }
}

.login_bg {
  width: 100%;
  height: 100%;
  display: flex;
  background: url('../assets/images/login/loginbc.png') no-repeat;
  flex-direction: column;
  background-size: 100% 100%;
}

.login_main {
  flex: 1;
  overflow: auto;
  display: flex;
}

.login-form {
  width: 614px;
  height: 474px;
  margin: auto;
  padding: 20px 0;
  background: url('../assets/images/login/loginForm.png') no-repeat 100% 100%;
}

.login-header {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
}

.login-oauth {
  display: flex;
  justify-content: space-around;
}

.login-form .el-divider {
  margin-top: 40px;
}

:deep(.el-input-group__append) {
  padding: 0;
  line-height: 0;
}

.login-code {
  width: 130px;
  height: 38px;
  vertical-align: bottom;
}

.login_config {
  position: absolute;
  top: 20px;
  right: 20px;
}

.el-dropdown-menu__item.selected {
  color: var(--el-color-primary);
}

@media (max-width: 1200px) {
  .login-form {
    width: 340px;
  }
}

@media (max-width: 1000px) {
  .login_main {
    display: block;
  }

  .login-form {
    width: 100%;
    padding: 20px 40px;
  }

  .login_adv {
    display: none;
  }
}
</style>